.App {
    text-align: center;
  }
  
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 80px;
  }
  
  .App-header {
    background-color: #222;
    height: 150px;
    padding: 20px;
    color: white;
  }
  
  .App-title {
    font-size: 1.5em;
  }
  
  .App-intro {
    font-size: large;
  }
  
  @keyframes App-logo-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }

  td.details-control {
    background: url('./details_open.png') no-repeat center center; /*Plus Image easily find on intent and I have stored this image into my scr/assets folder*/
    cursor: pointer;
    background-size: 30px;
}
tr.shown td.details-control {
    background: url('./details_close.png') no-repeat center center;/*Minus Image easily find on intent and I have stored this image into my scr/assets folder*/
    background-size: 30px;
}