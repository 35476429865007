table.dataTable>tbody>tr>.selected {
    background-color: rgba(13, 110, 253, 0.9);
    color: white
}

table.dataTable tbody td.select-checkbox,
table.dataTable tbody th.select-checkbox {
    position: relative
}

table.dataTable tbody td.select-checkbox:before,
table.dataTable tbody td.select-checkbox:after,
table.dataTable tbody th.select-checkbox:before,
table.dataTable tbody th.select-checkbox:after {
    display: block;
    position: absolute;
    top: 1.2em;
    left: 50%;
    width: 12px;
    height: 12px;
    box-sizing: border-box
}

table.dataTable tbody td.select-checkbox:before,
table.dataTable tbody th.select-checkbox:before {
    content: " ";
    margin-top: -6px;
    margin-left: -6px;
    border: 1px solid black;
    border-radius: 3px
}

table.dataTable tr.selected td.select-checkbox:before,
table.dataTable tr.selected th.select-checkbox:before {
    border: 1px solid white
}

table.dataTable tr.selected td.select-checkbox:after,
table.dataTable tr.selected th.select-checkbox:after {
    content: "✓";
    font-size: 20px;
    margin-top: -19px;
    margin-left: -6px;
    text-align: center;
    text-shadow: 1px 1px #b0bed9, -1px -1px #b0bed9, 1px -1px #b0bed9, -1px 1px #b0bed9
}

table.dataTable.compact tbody td.select-checkbox:before,
table.dataTable.compact tbody th.select-checkbox:before {
    margin-top: -12px
}

table.dataTable.compact tr.selected td.select-checkbox:after,
table.dataTable.compact tr.selected th.select-checkbox:after {
    margin-top: -16px
}

div.dataTables_wrapper span.select-info,
div.dataTables_wrapper span.select-item {
    margin-left: .5em
}

@media screen and (max-width: 640px) {

    div.dataTables_wrapper span.select-info,
    div.dataTables_wrapper span.select-item {
        margin-left: 0;
        display: block
    }
}